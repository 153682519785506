import React, { useState } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput
}
from 'mdb-react-ui-kit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { userLoginDefault } from '../Const/StateVariable';
import { CustomMessage } from '../Const/Spinner';
import { enqueueSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { setLoading } from '../Redux/CommonSlice';
import { signinUserData } from '../Const';
import { setAuthentication } from '../Redux/Reducer/AuthReducer';
import { useNavigate } from 'react-router-dom';

const Login:React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [passwordType,setPasswordType] = useState("password");
  const [userLogin,setUserLogin] = useState(userLoginDefault);

  const passwordToggle = () => {
    if(passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password')
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e?.target;
    const updatedUserLogin = {...userLogin};
      updatedUserLogin[name] = value;
    setUserLogin(updatedUserLogin);
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if(userLogin?.username === "" || userLogin?.password === ""){
      CustomMessage("Enter the required field",'error',enqueueSnackbar);
      return;
    }
    dispatch(setLoading(true));
    try{
      const response = await signinUserData(userLogin);
      const {data,status} = response;
      if(status.code === 200 && data !== null){
        dispatch(setAuthentication(data));
        // CustomMessage(status.message,'success',enqueueSnackbar);
        navigate("/app/checkin")
      }else{
        CustomMessage(status.message,'error',enqueueSnackbar);
      }
    }catch(error:any){
      CustomMessage(error?.message,'error',enqueueSnackbar);
    }finally{
      dispatch(setLoading(false));
    }
  }

  return (
    <MDBContainer fluid>
      <form onSubmit={handleSubmit}>
        <MDBRow className='d-flex justify-content-center align-items-center h-100'>
          <MDBCol col='12'>
            <MDBCard className='bg-dark text-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '400px'}}>
              <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>
                <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
                <p className="text-white-50 mb-5">Please enter your email and password!</p>
                <MDBInput 
                  wrapperClass='mb-4 mx-5 w-100' 
                  labelClass='text-white' 
                  label='Email address' 
                  id='formControlLg' 
                  type='email' size="lg"
                  style={{backgroundColor:"transparent",color:"white"}}
                  required
                  value={userLogin.username}
                  name='username'
                  onChange={handleChange} 
                />
                <div className='mb-4 mx-5 w-100' style={{ position: 'relative' }}>
                  <MDBInput 
                    labelClass='text-white' 
                    label='Password' 
                    id='formControlLg' 
                    type={passwordType} 
                    size="lg" 
                    style={{ backgroundColor: "transparent", color: "white" }} 
                    required 
                    value={userLogin.password}
                    name='password'
                    onChange={handleChange} 
                  />
                  <span onClick={passwordToggle} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer', color: 'white' }}>
                    {
                      passwordType === 'password' ? <VisibilityOffIcon color="primary"/> : <VisibilityIcon color="info"/>
                    }
                  </span>
                </div>
                <p className="small mb-3 pb-lg-2"><a className="text-white-50" href="#!" onClick={()=>navigate("/forgot-pwd")}>Forgot password?</a></p>
                <MDBBtn outline className='mx-2 px-5 text-white' size='lg'>
                  Login
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </form>
    </MDBContainer>
  );
}

export default Login;