import React, { useState } from "react";
import { Container, Box, Button, Typography, Divider } from "@mui/material";
import { decodeBase64 } from "../Const/const";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Redux/store/ReduxStore";
import ResponsiveDialog from "../Component/Dialog/ResponsiveDialog";
import CameraComponent from "../Component/CameraComponent";
import { setLoading } from "../Redux/CommonSlice";
import { uploadImageFile } from "../Const";
import { CustomMessage, Loader } from "../Const/Spinner";
import { enqueueSnackbar } from "notistack";
import { setAuthentication } from "../Redux/Reducer/AuthReducer";
import Logo from "../assets/company_logo.png";

const CheckInOut: React.FC = () => {
  const dispatch = useDispatch();
  const { transaction, user } = useSelector((state: RootState) => state.auth);
  const loading = useSelector((state:RootState)=>state.common.data.loading);
  const [show, setShow] = useState(false);

  const handleCapture = async (file: File,type:string,latitude:number | null,longitude:number |null, time:string | null) => {
    if(!latitude || !longitude) {
      CustomMessage("Latitude or Longitude not found",'error',enqueueSnackbar);
      return;
    }
    if(!time){
      CustomMessage("Time not added",'error',enqueueSnackbar);
      return;
    }
    const payload = new FormData();
      payload.append("filePath",file);
      // payload.append('createdBy',user.id.toString());
      payload.append('userId',user.id.toString());
      payload.append('latitude',latitude?.toString());
      payload.append("longitude",longitude?.toString());
      payload.append("createdTime",time);
      dispatch(setLoading(true));
      try{
        const response = await uploadImageFile(payload,type);
        if(response.status.code === 200){
          CustomMessage(response.status.message,'success',enqueueSnackbar);
          const updatedTransaction = { ...transaction };
          if (type === 'checkIn') {
            updatedTransaction.checkinImgId = response.data?.id;
            updatedTransaction.checkinFilePath = response.data?.filePath;
          } else {
            updatedTransaction.checkoutImgId = response.data?.id;
            updatedTransaction.checkoutFilePath = response.data?.filePath;
          }
          dispatch(setAuthentication({ user, transaction: updatedTransaction }));
        }else{
          CustomMessage(response.status.message,'success',enqueueSnackbar);
        }
      }catch(error:any){
        CustomMessage(error?.message,'error',enqueueSnackbar);
      }finally{
        dispatch(setLoading(false));
      }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      {
        loading && <Loader />
      }
      <Box textAlign="center" mb={4}>
        <Typography variant="h4">Welcome {user?.name || " "}</Typography>
      </Box>
      {
        (transaction?.checkoutImgId === 0 || transaction?.checkinImgId === 0) && (
          <Box display="flex" justifyContent="center" mb={4}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                borderRadius: "50%",
                width: 100,
                height: 100,
                fontSize: "1.2rem"
              }}
              onClick={() => setShow(!show)}
            >
              {transaction?.checkinImgId === 0 ? "Check In" : "Check out"}
            </Button>
          </Box>
        )
      }

      {show && transaction?.checkinImgId === 0 && (
        <ResponsiveDialog
          fullScreen={true}
          open={show && transaction?.checkinImgId === 0}
          title="Check In: Upload your picture"
          onClose={() => setShow(false)}
          content={
            <Box display="flex" flexDirection="column" alignItems="center">
              <CameraComponent
                facingMode="user"
                logoSrc={Logo}
                logoWidth={120}
                logoHeight={80}
                onCapture={(file,latitude,longitude,time)=>handleCapture(file,'checkIn',latitude,longitude,time)}
                buttonText="Take a picture"
              />
            </Box>
          }
          children={
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShow(false)}
              sx={{ mt: 2 }}
            >
              Close
            </Button>
          }
        />
      )}

      {!show && transaction?.checkoutImgId === 0 && transaction?.checkinImgId !== 0 && (
        <ResponsiveDialog
          fullScreen={true}
          open={!show && transaction?.checkoutImgId === 0 && transaction?.checkinImgId !== 0}
          title="Check Out: Upload your picture"
          onClose={() => setShow(true)}
          content={
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="body1" mb={2}>
                Note: If you checkout today then you can checkin tomorrow only.
              </Typography>
              <CameraComponent
                facingMode="user"
                logoSrc={Logo}
                logoWidth={120}
                logoHeight={80}
                onCapture={(file,latitude,longitude,time)=>handleCapture(file,'checkOut',latitude,longitude,time)}
                buttonText="Take a picture"
              />
            </Box>
          }
          children={
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShow(true)}
              sx={{ mt: 2 }}
            >
              Close
            </Button>
          }
        />
      )}

      {transaction?.checkinFilePath && transaction?.checkinImgId !== 0 && (
        <Box textAlign="center" mb={4}>
          <Typography variant="h5" title="Check In Image">Check In Image</Typography>
          <Divider sx={{ my: 2 }} />
          <img
            src={decodeBase64(transaction?.checkinFilePath)}
            alt="Check in"
            style={{ maxWidth: "100%", height: "auto", borderRadius: 8 }}
          />
        </Box>
      )}

      {transaction?.checkoutFilePath && transaction?.checkoutImgId !== 0 && (
        <Box textAlign="center" mb={4}>
          <Typography variant="h5" title="Check Out Image">Check Out Image</Typography>
          <Divider sx={{ my: 2 }} />
          <img
            src={decodeBase64(transaction?.checkoutFilePath)}
            alt="Check Out"
            style={{ maxWidth: "100%", height: "auto", borderRadius: 8 }}
          />
        </Box>
      )}
    </Container>
  );
};

export default CheckInOut;