import React, { useState } from "react";
import { LocalShipping } from '@mui/icons-material';
import { Card, CardContent, Chip, Divider, Grid, Paper, Typography } from "@mui/material";
import { Root } from "../../Const/componentStyles";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LoadingPacking from "./LoadingPackingTruck";
import LoadingPackingTruck from "./LoadingPackingTruck";
import LoadingPackingContainer from "./LoadingPackingContainer";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import Inventory2Icon from '@mui/icons-material/Inventory2'
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store/ReduxStore";

type LoadingIconsType = {
  [key: string]: JSX.Element;
};

const LoadingIcons: LoadingIconsType = {
    Truck:<LocalShippingIcon />,
    Container: <Inventory2Icon />
}

const LoadingMain:React.FC = () => {
    const user = useSelector((state:RootState)=>state.auth.user);
    const [showList,setShowList] = useState(false);
    const [selected,setSelected] = useState('');

    const categories = [
        {
            groupName: 'Loading',
            items: ["Truck", "Container"],
        },
    ];

    const handleSelect = (selected:string) => {
        setSelected(selected);
        setShowList(true);
    }
    
    const handleBack = () => {
        setShowList(false);
        setSelected('');
    }

    return(
        <div style={{margin:'15px'}}> 
            {!showList?
                <Grid container spacing={2}>
                    {categories.map((group, groupIndex) => (
                        <Grid item xs={12} key={groupIndex}>
                        <Root>
                            <Typography variant="h5" component="div">
                                <Divider textAlign="left"
                                    sx={{
                                        "&::before, &::after": {
                                            borderColor: "primary.main",
                                        },
                                    }}
                                >
                                    <Chip label={group.groupName} color='info' variant="outlined"/>
                                </Divider>
                            </Typography>
                            <Grid container spacing={2} className={'fade-in'}>
                                {group.items.map((category, categoryIndex) => (
                                    <Grid item xs={12} sm={6} md={6} lg={4} key={categoryIndex}>
                                        <Card
                                            onClick={() => handleSelect(category)}
                                        >
                                        <CardContent
                                            sx={{ display: 'flex', alignItems: 'center',cursor: 'pointer', }}
                                        >
                                                <Typography variant="h6" component="div">
                                                {LoadingIcons[category]}{category}
                                                </Typography>
                                        </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Root>
                </Grid>
            ))}
            </Grid>
            :
                <div>                    
                    <span>
                        <Chip
                            style={{ marginBottom:'15px'}}
                            label="Loading List"
                            color='info'
                            onClick={handleBack}
                            variant="outlined"
                        />
                    </span>
                    {
                        selected === "Truck" && <LoadingPackingTruck />
                    }
                    {
                        selected === "Container" && <LoadingPackingContainer />
                    }
                </div>
            }
        </div>
    )
}

export default LoadingMain;



// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyB-iPqwANZovNPtgxpuuvSempO7kvvTvs8",
//   authDomain: "krishca-inventory.firebaseapp.com",
//   projectId: "krishca-inventory",
//   storageBucket: "krishca-inventory.appspot.com",
//   messagingSenderId: "914771547030",
//   appId: "1:914771547030:web:01c70e4c5ae7b4ccea43b7",
//   measurementId: "G-J90S4DL8GF"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);