export const BASE_URL = 'https://catalytix.in/live/api/krishca-inventory/api/'; //production
// export const BASE_URL = 'http://localhost/MyApp/api/';

export const LOGIN = BASE_URL + 'auth/login';
export const UPLOAD_FILES = BASE_URL + 'auth/uploadFiles/';
export const GET_LIST_STOCK_ITEMS = BASE_URL + 'Inspection/getAllItems/';
export const CREATE_STOCK_DETAILS = BASE_URL + 'Inspection/updateItems';
export const GET_LIST_LOADING_PACKING = BASE_URL + 'Inspection/getLoadingMasterList/';
export const ENTRY_LOADIN_PACKING = BASE_URL + 'Inspection/insertLoadingData';
export const GET_BY_ID_LOADING_PACKING = BASE_URL + 'Inspection/getLoadingDataById/';
export const UPDATE_LOADING_PACKING = BASE_URL + 'Inspection/updateLoadingData/';
export const UPDATE_LOADING_PACKING_STATUS = BASE_URL + "Inspection/updateLoadingStatus/";
export const GET_LIST_STOCK_REPORT = BASE_URL + "Inspection/getExportItems/";
export const CHANGE_PASSWORD = BASE_URL + "auth/changePwd";
export const FORGOT_PASSWORD = BASE_URL + "auth/forgorPwd";
export const INSERT_ITEM_MASTER = BASE_URL + "Inspection/createItems";
export const UPDATE_ITEM_MASTER = BASE_URL + 'Inspection/updateItemsMaster';
export const GET_ITEM_MASTER_ID = BASE_URL + "Inspection/getItemById/";
export const GET_WORKFLOW_HISTORY = BASE_URL + "Inspection/getWorkflowList/";