import React, { useState } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput
}
from 'mdb-react-ui-kit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { enqueueSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserChangePwdInterface } from '../../Const/type';
import { RootState } from '../../Redux/store/ReduxStore';
import { CustomMessage } from '../../Const/Spinner';
import { setLoading } from '../../Redux/CommonSlice';
import { changePwd } from '../../Const';
import { setAuthentication } from '../../Redux/Reducer/AuthReducer';

const ChangePassword:React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state:RootState)=>state.auth.user);

  const [passwordType,setPasswordType] = useState("password");
  const [newPasswordType,setNewPasswordType] = useState("password");
  const [userLogin,setUserLogin] = useState<UserChangePwdInterface>({
    newPassword:"",
    password: "",
    userId: user?.id,
    username: user?.username
  });

  const passwordToggle = () => {
    if(passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password')
  }
  const newPasswordToggle = () => {
    if(newPasswordType === 'password') {
      setNewPasswordType('text');
      return;
    }
    setNewPasswordType('password')
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e?.target;
    const updatedUserLogin = {...userLogin};
      updatedUserLogin[name] = value;
    setUserLogin(updatedUserLogin);
  }

  const validateNewPassword = (password: string) => {
    const passwordPattern = /^(?=.*[A-Z])(?=.*[@#$%^&*])(?=.*[a-z])(?=.*\d).{8,}$/;
    return passwordPattern.test(password);
  }
  
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if(userLogin?.username === "" || userLogin?.password === ""|| userLogin?.newPassword === ""){
      CustomMessage("Enter the required field",'error',enqueueSnackbar);
      return;
    }
    if (!validateNewPassword(userLogin.newPassword)) {
      CustomMessage("New password must be at least 8 characters long and include an uppercase letter, a special character, a number, and lowercase letters.", 'error', enqueueSnackbar);
      return;
    }
    dispatch(setLoading(true));
    try{
      const response = await changePwd(userLogin);
      const {data,status} = response;
      if(status.code === 200){
        CustomMessage(status.message,'success',enqueueSnackbar);
        navigate("/");
        dispatch(setAuthentication({
          transaction:{
            checkinFilePath:"",
            checkinImgId:0,
            checkinLatitude:"",
            checkinLongitude:"",
            checkoutFilePath:"",
            checkoutImgId:0,
            checkoutLatitude:"",
            checkoutLongitude:'',
            date:'',
            id:0,
            userId:0,
          },
          user:{
            id:0,
            name:"",
            password:"",
            username:"",
            roleMapping:[]
          }
        }));
        CustomMessage("Login now with new password",'info',enqueueSnackbar);
      }else{
        CustomMessage(status.message,'error',enqueueSnackbar);
      }
    }catch(error:any){
      CustomMessage(error?.message,'error',enqueueSnackbar);
    }finally{
      dispatch(setLoading(false));
    }
  }

  return (
    <MDBContainer fluid>
      <form onSubmit={handleSubmit}>
        <MDBRow className='d-flex justify-content-center align-items-center h-100'>
          <MDBCol col='12'>
            <MDBCard className='bg-dark text-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '400px'}}>
              <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>
                <h2 className="fw-bold mb-2 text-uppercase">Change the password</h2>
                <p className="text-white-50 mb-5">Please enter your new password!</p>
                <MDBInput 
                  wrapperClass='mb-4 mx-5 w-100' 
                  labelClass='text-white' 
                  label='Email address' 
                  id='formControlLg' 
                  type='email' size="lg"
                  style={{backgroundColor:"transparent",color:"white"}}
                  required
                  value={userLogin.username}
                  name='username'
                  onChange={handleChange}
                  disabled 
                />
                <div className='mb-4 mx-5 w-100' style={{ position: 'relative' }}>
                  <MDBInput 
                    labelClass='text-white' 
                    label='Password' 
                    id='formControlLg' 
                    type={passwordType} 
                    size="lg" 
                    style={{ backgroundColor: "transparent", color: "white" }} 
                    required 
                    value={userLogin.password}
                    name='password'
                    onChange={handleChange} 
                  />
                  <span onClick={passwordToggle} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer', color: 'white' }}>
                    {
                      passwordType === 'password' ? <VisibilityOffIcon color="primary"/> : <VisibilityIcon color="info"/>
                    }
                  </span>
                </div>
                <div className='mb-4 mx-5 w-100' style={{ position: 'relative' }}>
                  <MDBInput 
                    labelClass='text-white' 
                    label='New Password' 
                    id='formControlLg' 
                    type={newPasswordType} 
                    size="lg" 
                    style={{ backgroundColor: "transparent", color: "white" }} 
                    required 
                    value={userLogin.newPassword}
                    name='newPassword'
                    onChange={handleChange} 
                  />
                  <span onClick={newPasswordToggle} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer', color: 'white' }}>
                    {
                      newPasswordType === 'password' ? <VisibilityOffIcon color="primary"/> : <VisibilityIcon color="info"/>
                    }
                  </span>
                </div>
                <MDBBtn outline className='mx-2 px-5 text-white' size='lg'>
                  Submit
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </form>
    </MDBContainer>
  );
}

export default ChangePassword;