import { enqueueSnackbar } from "notistack";
import { CustomMessage } from "./Spinner";
import dayjs from "dayjs";

export const decodeBase64 = (base64String: string | null) => {
  try {
    // Decode the base64 string
    // const decoded = atob(base64String ?? '');

    // Create a data URL
    const dataURL = `data:image/jpeg;base64,${base64String}`;
    return dataURL;
  } catch (error) {
    CustomMessage('Error decoding base64 image','error',enqueueSnackbar);
    console.error('Error decoding base64 string:', error);
    return "";
  }
};

export const dateFormatter = (responseDate:string) => {
  const date = new Date(responseDate);
    // Options for formatting
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  // Format the date
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
}


export const stringToTime = (dateTime:string | null) => {
  if(dateTime === null){
    return "N/A";
  }
  const time = dayjs(dateTime).format("HH:mm:ss");
  return time;
}

export const TimeStampValue = ():string=> {
  return dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
}

export const roleRender = (roles:string[]) => {
  const data =["KRISHCA_VEDANTA_SV"].some(role => roles.includes(role));
  // console.log(data);
  return data
}