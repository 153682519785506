// src/components/Header.tsx
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import SignOut from '@mui/icons-material/Logout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Redux/store/ReduxStore';
import Logo from "../assets/company_logo.png";
import { logOff, setAuthentication } from '../Redux/Reducer/AuthReducer';

type HeaderProps = {
  toggleSidebar: () => void;
};

const Header: React.FC<HeaderProps> = ({ toggleSidebar }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state: RootState) => state.auth);

    useEffect(() => {
      if (location.pathname === '/app') {
        navigate('checkin');
      }
      else if(location.pathname === '/Account' ){
        navigate('menu-suggestion');
      }
      // else{
      //   return null;
      // }
    }, [location, navigate,user]);

    const logout = () => {
      dispatch(logOff())
    }
    
  return (
    <AppBar position="static" color='transparent' style={{padding: '0 2% 0 2%'}}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleSidebar} style={{marginRight:"30px"}}>
          <MenuIcon titleAccess='menu' />
        </IconButton>
        <Box flexGrow={1}>
          <img src={Logo} alt="Logo" style={{ maxWidth: '100%', width:'150px', height: 'auto', maxHeight: '80px' }} />
        </Box>
        <IconButton edge="end" color="inherit" aria-label="menu" onClick={logout} style={{marginRight:"30px"}}>
          <SignOut titleAccess='Sign out' />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
