import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { ThemeProvider,createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    primary: {
      main: '#fff', // White color for labels and enabled text
    },
    text: {
      // primary: '#fff', // White color for labels and enabled text
      primary:'#000',
      disabled: '#ccc', // Grey color for disabled text
    },
    background: {
      // paper: '#000', // Black background color
      paper:'#fff'      
    },
  },
});


const Root = styled("div")(({ theme }) => ({
  width: "100%",
  ...theme.typography.overline,
  "& > :not(style) ~ :not(style)": {
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
}));

export {
  theme,
  Root,
};