import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Common } from "../Const/type";

interface CommonState {
  data: Common;
}

const initialCommonState: CommonState = {
    data:{
        loading:false,
    },
}


const commonSlice = createSlice({
  name: "common",
  initialState: initialCommonState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
        state.data.loading = action.payload;
    },
  }
})

export const {
    setLoading,
} = commonSlice.actions;

export const CommonReducer = commonSlice.reducer;
export { commonSlice };