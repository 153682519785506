import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./StockEntry.css"; // Your custom CSS for styling
import { ColDef, ITooltipParams } from "ag-grid-community";
import { itemGetListType } from "../../Const/type";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../Redux/CommonSlice";
import { CustomMessage, Loader } from "../../Const/Spinner";
import { RootState } from "../../Redux/store/ReduxStore";
import { enqueueSnackbar } from "notistack";
import { createStockData, getListOfItemData } from "../../Const";
import { TextField, Select, MenuItem, InputLabel, FormControl, Button, Paper, Typography, Box, Divider } from "@mui/material";
import CustomNumberEditor from "./CustomNumberEditor";

const StockEntry: React.FC = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state:RootState)=>state.auth.user.id);
  const loading = useSelector((state: RootState) => state.common.data.loading);
  
  const [action, setAction] = useState<string>("Inward");
  const [comments, setComments] = useState<string>("");
  const gridApiRef = useRef<any>(null);
  const [itemList, setItemList] = useState<itemGetListType[]>([]);
  const [info, setInfo] = useState<string>('Note: Double click to Edit the New qty...');
  const [selectedPlant,setSelectedPlant] = useState("--Select--");
  const [listPlant,setListPlant] = useState([
    "--Select--",
    "Plant1","Plant2"]);

  const handlePlantChange = (event: any) => {
    setSelectedPlant(event.target.value);
    getItemListData(event.target.value);
  };


  const getItemListData = async (selectedPlantData:string) => {
    if(selectedPlantData === "--Select--" || selectedPlantData === ""){
      CustomMessage("Select the plant",'error',enqueueSnackbar);
      return;
    }
    dispatch(setLoading(true));
    try {
      const response = await getListOfItemData(selectedPlantData);
      let { data, status } = response;
      if (status.code === 200 && data?.length > 0) {
        setItemList(data);
      } else {
        CustomMessage(status.message, 'error', enqueueSnackbar);
      }
    } catch (error: any) {
      CustomMessage(error?.message, 'error', enqueueSnackbar);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const validateInput = (value: any) => {
    const regex = /^[0-9]+$/;
    return regex.test(value);
  };

  const handleCellValueChanged = (params: any) => {
    if (!validateInput(params.newValue)) {
      params.node.setDataValue(params.column.getId(), params.oldValue);
      CustomMessage('Please enter a valid positive number.', 'error', enqueueSnackbar);
    }
  };

  const toolTipValueGetter = (params: ITooltipParams) => params.value == null || params.value === '' ? '- Missing -' : params.value;
  const columnDefs: ColDef[] = [
    {
      headerName: "Item - Qty",
      field: "itemName",
      filter: "agSetColumnFilter",
      sortable: true,
      editable: false,
      // tooltipValueGetter: toolTipValueGetter,
      // tooltipField: "itemName",
      valueFormatter:(Params)=>`${Params.data?.itemName} - ${Params.data?.stockQty}`,
      flex: 0.5,
      // pinned: 'left',
    },
    {
      headerName: "New Qty",
      field: "newQty",
      filter: "agSetColumnFilter",
      sortable: true,
      // editable: (params)=>params.data?.stockQty != 0,
      editable: true,
      tooltipValueGetter: toolTipValueGetter,
      tooltipField: "newQty",
      flex: 0.4,
      cellEditor: 'agTextCellEditor',
      cellEditorParams: {
        useFormatter: true,
        formatter: (value: any) => {
          return isNaN(value) ? '' : value;
        },
        cellEditor: CustomNumberEditor,
        onCellValueChanged: handleCellValueChanged,
      },
    },
    // {
    //   headerName: "Existing Qty",
    //   field: "stockQty",
    //   filter: "agSetColumnFilter",
    //   sortable: true,
    //   editable: false,
    //   tooltipValueGetter: toolTipValueGetter,
    //   tooltipField: "stockQty",
    //   // flex: 0.5,
    // },
  ];

  const handleActionChange = (event: any) => {
    setAction(event.target.value);
  };

  const handleCommentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComments(event.target.value);
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      // minWidth: 100,
      filter: true,
    };
  }, []);

  const getRowId = useMemo(() => {
    return (params: any) => params?.data?.itemId;
  }, []);

  useEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.api.sizeColumnsToFit();
    }
  }, []);

  const handleClick = async () => {
    // Check if any newQty is not a number
    const invalidItems = itemList.filter(item => isNaN(item.newQty));

    if (invalidItems.length > 0) {
      CustomMessage('Enter the valid number','error',enqueueSnackbar);
      return; // Prevent further action
    }

      // Additional validation for "Outward" action
    if (action === "Outward") {
      const invalidOutwardItems = itemList.filter(item => 
        item.newQty < 0 || // Prevent negative quantities
        item.stockQty === 0 && item.newQty > 0 || // Prevent newQty if stockQty is 0
        item.newQty > item.stockQty // Prevent newQty greater than available stockQty
      );

      if (invalidOutwardItems.length > 0) {
        CustomMessage('Cannot submit: New quantity cannot be negative, exceed the available stock, or be greater than 0 when stock quantity is 0 for Outward action', 'error', enqueueSnackbar);
        return; // Prevent further action
      }
    }
    if(itemList.length === 0){
      CustomMessage("data not found",'error',enqueueSnackbar);
      return;
    }
    dispatch(setLoading(true));
    const payload = {
      data:itemList,
      comments: comments,
      updatedBy:userId,
      action: action
    }
    try{
      const response = await createStockData(payload);
      if(response?.status.code === 200){
        CustomMessage(response?.status?.message,'success',enqueueSnackbar);
        setComments("");
        getItemListData(selectedPlant);
      }else{
        CustomMessage(response?.status.message,'error',enqueueSnackbar);
      }
    }catch(error:any){
      CustomMessage(error?.message,'error',enqueueSnackbar);
    }finally{
      dispatch(setLoading(false));
    }
  } 

  return (
    <div className="stock-entry-container">
      {loading && <Loader />}

      <FormControl className="plant-dropdown" fullWidth>
        <InputLabel id="plantSelect-label">Select Plant</InputLabel>
        <Select
          labelId="plantSelect-label"
          id="plantSelect"
          value={selectedPlant}
          label="Select Plant"
          onChange={handlePlantChange}
        >
          {listPlant.map((plant) => (
            <MenuItem key={plant} value={plant}>{plant}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Divider />

      <FormControl className="action-dropdown" fullWidth>
        <InputLabel id="actionSelect-label">Select Action</InputLabel>
        <Select
          labelId="actionSelect-label"
          id="actionSelect"
          value={action}
          label="Select the Action"
          onChange={handleActionChange}
        >
          {["Inward","OutWard"].map(val=>(
            <MenuItem key={val} value={val}>{val}</MenuItem>
          ))}
          {/* <MenuItem value="Inward">Inward</MenuItem>
          <MenuItem value="Outward">Outward</MenuItem> */}
        </Select>
      </FormControl>

      <Paper elevation={3} style={{ padding: '6px', backgroundColor: '#f5f5f5' }}>
        <Box>
          <Typography variant="body2">{info}</Typography>
        </Box>
      </Paper>
      <div className="ag-theme-alpine ag-grid-container">
        <AgGridReact
          className="ag-theme-alpine"
          defaultColDef={defaultColDef}
          rowData={itemList}
          columnDefs={columnDefs}
          onGridReady={(params) => (gridApiRef.current = params)}
          domLayout="autoHeight"
          onCellValueChanged={handleCellValueChanged}
          pagination={false}
          paginationPageSize={20}
          getRowId={getRowId}
          suppressMenuHide={true}
        />
      </div>

      <TextField
        id="standard-multiline-static"
        type="text"
        className="comments-input"
        rows={4}
        multiline
        value={comments}
        onChange={handleCommentsChange}
        label="Comments"
        placeholder="Enter comments..."
        fullWidth
      />

      <Button variant="contained" color="success" onClick={handleClick}>Submit</Button>
    </div>
  );
};

export default StockEntry;