import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { userDataInterface } from "../../Const/type";

export const initialState: userDataInterface = {
    user: {
        id: 0,
        username: "",
        name: "",
        password: "",
        roleMapping:[]
    },
    transaction: {
        id: 0,
        userId: 0,
        date: null,
        checkinImgId: 0,
        checkinFilePath: null,
        checkinLatitude: null,
        checkinLongitude: null,
        checkoutLatitude: null,
        checkoutLongitude: null,
        checkoutImgId: 0,
        checkoutFilePath: null
    }
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setAuthentication: (state, action: PayloadAction<userDataInterface>) => {
            // Directly assign the values to the state
            const { transaction, user } = action.payload;
            state.transaction = transaction;
            state.user = user;
        },
        logOff: (state, action: PayloadAction<void>) => {
            state.user.id = 0;
        },
    },
});

export const { setAuthentication, logOff } = authSlice.actions;
export const authReducer = authSlice.reducer;
export default authSlice;