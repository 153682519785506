import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Step, StepConnector, StepContent, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import CameraComponent from '../../Component/CameraComponent';
import Logo from "../../assets/company_logo.png";
import { defaultDataLoadingPacking } from '../../Const/StateVariable';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../Redux/CommonSlice';
import { RootState } from '../../Redux/store/ReduxStore';
import { CustomMessage, Loader } from '../../Const/Spinner';
import { enqueueSnackbar } from 'notistack';
import { getByIdLoadingData, getListLoadingPackingData, getWorkflowData, insertLoadingPackingEntry, updateLoadingState, uploadImageFile } from '../../Const';
import { decodeBase64, roleRender } from '../../Const/const';
import { CompletedLoadingPackType, defaultLoadingPackingList, LashingLoadingPackType, TarpaulinLoadingPackType, workflowDataList } from '../../Const/type';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ITooltipParams } from 'ag-grid-community';
import { Add, Camera, Timeline } from '@mui/icons-material';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import swal from 'sweetalert';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';

// Custom connector with zigzag effect
const ZigzagStepConnector = styled(StepConnector)(({ theme }) => ({
  [`& .MuiStepConnector-line`]: {
    borderColor: theme.palette.divider,
    borderLeft: '2px dashed', // Dashed lines for zigzag effect
    marginLeft: theme.spacing(2), // Adjust margins for zigzag effect
    minHeight: 30, // Minimum height for spacing
  },
}));

const ZigzagStepLabel = styled(StepLabel)(({ theme }) => ({
  '&.MuiStepLabel-root': {
    position: 'relative',
    marginBottom: theme.spacing(3),
  },
  '&:nth-of-type(odd) .MuiStepLabel-label': {
    transform: 'translateX(-30%)', // Move left for odd steps
    textAlign: 'right',
  },
  '&:nth-of-type(even) .MuiStepLabel-label': {
    transform: 'translateX(30%)', // Move right for even steps
    textAlign: 'left',
  },
}));

const LoadingPackingTruck:React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state:RootState)=>state.common.data.loading);
  const user = useSelector((state:RootState)=>state.auth.user.id);
  const userDetails = useSelector((state:RootState)=>state.auth.user);
  const [data,setData] = useState(defaultDataLoadingPacking);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [captureMode, setCaptureMode] = useState('');
  const [loadingPackingList,setLoadingPackingList] = useState<defaultLoadingPackingList[] >([]);
  const [show,setShow] = useState(false);
  const gridApiRef = useRef<any>(null);
  const cameraRef = useRef<HTMLDivElement | null>(null);
  const [view,setView] = useState(false);
   const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false);
   const materialList = ["Ingot/Sow / Cast bar","T-INGOT","WIREROD","BILLET"];
   const [timeLine,setTimeLine] = useState(false);
   const [workflowList,setWorkflowList] = useState<workflowDataList[]>([]);

  const dateFormatter = (responseDate:string) => {
    const date = new Date(responseDate);
     // Options for formatting
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    // Format the date
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  const toolTipValueGetter = (params: ITooltipParams) => params.value == null || params.value === '' ? '- Missing -' : params.value;
  const columnDefs: ColDef[] = [
    {
      headerName: "Vehicle",
      field: "vehNum",
      filter: "agSetColumnFilter",
      sortable: true,
      editable: false,
      tooltipValueGetter: toolTipValueGetter,
      tooltipField: "vehNum",
    },
    {
      headerName: "Status",
      field: "status",
      filter: "agSetColumnFilter",
      sortable: true,
      editable: false,
      tooltipValueGetter: toolTipValueGetter,
      tooltipField: "status",
      cellRenderer: (params:any) => (
        <div style={{color: params?.data?.status=== "Completed" ? "green":'black' }}>{params?.data?.status}</div>
      )
      // flex: 1,
    },
    {
      headerName: "Date",
      field: "date",
      filter: "agSetColumnFilter",
      sortable: true,
      editable: false,
      tooltipValueGetter: toolTipValueGetter,
      tooltipField: "date",
      // flex: 1,
      valueFormatter:(params)=>dateFormatter(params?.data?.date)
    },
    {
      headerName: "Action",
      field: "action",
      filter: "agSetColumnFilter",
      sortable: true,
      editable: false,
      // tooltipValueGetter: toolTipValueGetter,
      // tooltipField: "status",
      // flex: 1,
      cellRenderer:(params:any) => (
        <div style={{display:'flex',justifyContent:"space-between",flexWrap:'wrap'}}>
          {
            (
              params?.data?.status !== "Completed" &&
              !(roleRender(userDetails.roleMapping) && (params?.data?.status == "Hand Over" || params?.data?.status == "Assigned")) &&
              !(userDetails.roleMapping.includes("VEDANTA_OSL") && ["Assigned","Approved", "Hand Over", "Completed", "Packing Inprogress"].includes(params.data?.status))
            ) && (
              <>
              <Chip variant='outlined' label="Edit" color='info' onClick={()=>getData(params?.data?.id)}/>
              </>
            )
          }
          <Chip variant='outlined' label="View" color='info' onClick={()=>{
            setView(true);
            getData(params?.data?.id);
          }}/>
          {
            (params?.data?.status === "Assigned" && roleRender(userDetails.roleMapping)) && (
              <Chip variant='filled' label="Approve" color='info' onClick={()=>approveData(params?.data?.id,params?.data?.vehNum)} />
            )
          }
          <div onClick={()=>handleWorkflow(params?.data?.id)} style={{cursor:"pointer",color:"blue"}}>
            <Timeline />
          </div>
        </div>
      )
    },
  ];

  const handleWorkflow = async (loadingId:number) => {
    dispatch(setLoading(true));
    try{
      const response = await getWorkflowData(loadingId);
      if(response.status.code === 200 && response?.data?.length > 0){
        setWorkflowList(response.data);
        // CustomMessage(response.status.message,'success',enqueueSnackbar);
        setTimeLine(true);
      }else{
        CustomMessage(response.status.message,'error',enqueueSnackbar);
      }
    }catch(error:any){
      CustomMessage(error?.message,'error',enqueueSnackbar);
    }finally{
      dispatch(setLoading(false));
    }
  }

  const approveData = (id:number,vehNum:string) => {
    swal({
        className: "red-bg",
        title: "This action will start the TAT for Krishca.",
        text: "you will not be able to recover",
        icon: "info", // Use 'icon' instead of 'type'
        buttons: ["Cancel", "Approve"], // Use an array for buttons
        dangerMode: false,
    }).then(async(confirmed) => {
        if (confirmed) {
          await approveLoading(id,vehNum);
        }
    })
  }

  const approveLoading = async (id:number,vehNum:string) => {
    dispatch(setLoading(true));
    try{
      const response = await updateLoadingState(id,userDetails.id,vehNum);
      if(response.status.code === 200){
        await getListOfLoadingPackingData();
        CustomMessage(response.status.message,'success',enqueueSnackbar);
      }else{
        CustomMessage(response.status.message,'error',enqueueSnackbar);
      }
    }catch(error:any){
      CustomMessage(error?.message,'error',enqueueSnackbar);
    }finally{
      dispatch(setLoading(false));
    }
  }

  const getData = async (id:number) => {
    dispatch(setLoading(true));
    try{
      const response = await getByIdLoadingData(id);
      if(response?.status?.code === 200){
        setData(response?.data);
        if(response?.data?.materialName != "" && response?.data?.materialName != null && !(materialList.includes(response?.data?.materialName))){
          setIsOtherSelected(true);
        }
        setShow(true);
      }else{
        CustomMessage(response?.status?.message,'error',enqueueSnackbar);
      }
    }catch(error:any){
      CustomMessage(error?.message,'error',enqueueSnackbar);
    }finally{
      dispatch(setLoading(false));
    }
  }

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      // minWidth: 50,
      filter: true,
    };
  }, []);

  const getRowId = useMemo(() => {
    return (params: any) => params?.data?.id;
  }, []);

  useEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.api.sizeColumnsToFit();
    }
  }, []);

  useEffect(()=>{
    if(loadingPackingList?.length === 0) {
      getListOfLoadingPackingData();
    }
  },[loadingPackingList]);

  const getListOfLoadingPackingData = async () => {
    dispatch(setLoading(true));
    try{
      const response = await getListLoadingPackingData(
        "Truck",
      );
      if(response?.data?.length >0){
        setLoadingPackingList(response?.data);
      }else{
        CustomMessage('No data','error',enqueueSnackbar);
      }
    }catch(error:any){
      CustomMessage(error?.message,'error',enqueueSnackbar);
    }finally{
      dispatch(setLoading(false));
    }
  }

  const updateFileData = (dataResponse:any,time:string) => {
    const updatedData = {...data};
    if (captureMode === 'number') {
      updatedData.vehAttachmentId = dataResponse?.id;
      updatedData.vehFilePath = dataResponse?.filePath;
      updatedData.vehicleTat = time;
    } else if (captureMode === 'lashing') {
      const newLashing: LashingLoadingPackType = {
       lashingAttachmentId: dataResponse?.id,
       lashingType: "Lashing",
       lashingFilePath: dataResponse?.filePath,
       createdTime:time
      };
      updatedData.lashing = [...data.lashing, newLashing];
    } else if (captureMode === 'tarpaulin') {
      const newTarpaulin: TarpaulinLoadingPackType = {
       tarpaulinAttachmentId: dataResponse?.id,
       tarpaulinFilePath: dataResponse?.filePath,
       tarpaulinType: "Tarpaulin",
       createdTime:time
      };
      updatedData.tarpaulin = [...data.tarpaulin, newTarpaulin];
    } else if (captureMode === 'completed') {
        const newCompleted: CompletedLoadingPackType = {
         completedAttachmentId: dataResponse?.id,
         completedFilePath: dataResponse?.filePath,
         completedAttachmentType: "Completed",
         createdTime:time
        };
      updatedData.completed = [...data.completed, newCompleted];
    }
    setData(updatedData);
    setIsCameraOpen(false);
  }

  const getType = () => {
    if (captureMode === 'number') {
      return "Vehicle";
    } else if(captureMode === 'lashing') {
      return 'LoadingLashing';
    } else if(captureMode === 'tarpaulin') {
      return 'LoadingTarpaulin';
    } else if(captureMode === 'completed') {
      return "LoadingCompleted"
    }
    return "";
  }
  const handleCapture = async (file:File, latitude: number | null, longitude: number | null,time:string | null) => {
    if(!latitude || !longitude) {
      CustomMessage("Latitude or Longitude not found",'error',enqueueSnackbar);
      return;
    }
    if(!time){
      CustomMessage("Time not added",'error',enqueueSnackbar);
      return;
    }
    dispatch(setLoading(true));
       const payload = new FormData();
      payload.append("filePath",file);
      payload.append('userId',user.toString());
      payload.append('latitude',latitude?.toString());
      payload.append("longitude",longitude?.toString());
      payload.append("createdTime",time);
      dispatch(setLoading(true));
      try{
        const response = await uploadImageFile(payload,getType());
        if(response?.status?.code === 200){
          CustomMessage(response?.status?.message,'success',enqueueSnackbar);
          updateFileData(response?.data,time);
        }else{
          CustomMessage(response?.status?.message,'error',enqueueSnackbar);
        }
      }catch(error:any){
        CustomMessage(error?.message,'error',enqueueSnackbar);
      }finally{
        dispatch(setLoading(false));
      }
  }

const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const updatedData = { ...data, [name]: value };
    setData(updatedData);
};

 const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    const updatedData = { ...data, [name as string]: value as string };

    if (value === 'Others') {
      setIsOtherSelected(true);
      updatedData.materialName = '';
    } else {
      setIsOtherSelected(false);
    }
    setData(updatedData);
  };

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const updatedData = { ...data, [name]: value };
    setData(updatedData);
  };

  const handleTextFieldTruckChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const updatedData = { ...data, [name]: value };
    setData(updatedData);
  };

  const handleOpenCamera = async(mode:string) => {
    // setCaptureMode(mode);
    // setIsCameraOpen(true);
    try {
      // Check for camera permission
      const cameraPermission = await navigator.mediaDevices.getUserMedia({ video: true });

      // Check for location permission
      const locationPermission = new Promise<void>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          () => resolve(),
          (error) => reject(error)
        );
      });

      await Promise.all([cameraPermission, locationPermission]);

      setCaptureMode(mode);
      setIsCameraOpen(true);
    } catch (error) {
      if (error instanceof GeolocationPositionError) {
        CustomMessage("Location permission denied",'error',enqueueSnackbar);
        CustomMessage("Location permission is required to use this feature.",'error',enqueueSnackbar);
      } else {
        CustomMessage("Camera permission denied or not available.",'error',enqueueSnackbar);
        CustomMessage("Camera permission is required to use this feature.",'error',enqueueSnackbar);
      }
    }
  };

  const handleClear = () => {
    setView(false);
    setData(
      {
        completed: [],
        id:0,
        lashing: [] as LashingLoadingPackType[],
        tarpaulin: [] as TarpaulinLoadingPackType[],
        status:'',
        vehAttachmentId:0,
        vehFilePath:'',
        vehNum:"",
        vehType:"vehicle",
        createdBy:0,
        updatedBy:0,
        materialName:"",
        workersName:"",
        loadingName:'Truck',
        truckType:"",
        weight:"",
        remainingTat:"",
        vehicleTat:"",
        approvedTat:"",
        assignedTat:"",
        completedTat:"",
        handoverTat:''
      }
    )
    setIsCameraOpen(false);
    setCaptureMode("");
    setShow(false);
  }

  const handleSave = async (type:string) => {
    const currentTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const dateTime = dayjs().format("YYYY-MM-DD HH:mm");
    // Calculate the difference in minutes
    const updatedData = {...data};
    const timeDiffMinutes = dayjs(currentTime).diff(dayjs(updatedData.vehicleTat), 'minute');
    const timeDiffRemainMinutes = dayjs(currentTime).diff(dayjs(updatedData.remainingTat), 'minute');
    if(updatedData?.vehNum === ""){
      CustomMessage("Add the Vehicle number",'error',enqueueSnackbar);
      return;
    }
    dispatch(setLoading(true));
    if(type === "normal"){
      if(updatedData.completed?.length >0){
        if(updatedData.weight != ""){
          updatedData.status = "Completed";
          updatedData.completedTat = dateTime;
        }
        // else{
        //   updatedData.remainingTat = `${timeDiffRemainMinutes} min`;
        //   updatedData.status = "Hand Over";
        // }
      }else if( !["Assigned","Approved","Completed","Hand Over","Packing Inprogress"].includes(updatedData.status) ){
        updatedData.status = "Inprogress";
      }else if(updatedData.status == "Approved"){
        updatedData.status = "Packing Inprogress"
      }
    }else if(type == "Assigned"){
      updatedData.vehicleTat = `${timeDiffMinutes} min`;
      updatedData.status = type;
      updatedData.assignedTat = dateTime;
    } else if(type == "Completed"){
      updatedData.remainingTat = `${timeDiffRemainMinutes} min`;
      updatedData.status = type;
      updatedData.completedTat = dateTime;
    }else if(type == "Hand Over"){
      updatedData.remainingTat = `${timeDiffRemainMinutes} min`;
      updatedData.status = "Hand Over";
      updatedData.handoverTat = dateTime;
    }
    if(updatedData?.id === 0){
      updatedData.createdBy = user;
    }else{
      updatedData.updatedBy = user;
    }
    updatedData.loadingName = "Truck";
    try{
      const response = await insertLoadingPackingEntry(updatedData);
      if(response?.status?.code === 200){
        CustomMessage(response?.status?.message,'success',enqueueSnackbar);
        getListOfLoadingPackingData();
        handleClear();
      }else{
        CustomMessage(response?.status?.message,'success',enqueueSnackbar);
      }
    }catch(error:any){
      CustomMessage(error?.message,'error',enqueueSnackbar);
    }finally{
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (isCameraOpen && cameraRef.current) {
      cameraRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isCameraOpen]);


  const sortedTarpaulinData = data?.tarpaulin?.slice().sort((a, b) => a.tarpaulinAttachmentId - b.tarpaulinAttachmentId);
  const tarpaulinStartTime = sortedTarpaulinData?.length ? sortedTarpaulinData[0].createdTime.split(' ')[1] : 'N/A';
  const tarpaulinEndTime = sortedTarpaulinData?.length ? sortedTarpaulinData[sortedTarpaulinData.length - 1].createdTime.split(' ')[1] : 'N/A';

  const sortedLashingData = data?.lashing?.slice().sort((a, b) => a.lashingAttachmentId - b.lashingAttachmentId);
  const lashingStartTime = sortedLashingData?.length ? sortedLashingData[0].createdTime.split(' ')[1] : 'N/A';
  const lashingEndTime = sortedLashingData?.length ? sortedLashingData[sortedLashingData.length - 1].createdTime.split(' ')[1] : 'N/A';


  return (
    <>
    {loading && <Loader />} 
    {
      show ? (
        <Box p={2}>
          <Typography variant="h6">Loading Information</Typography>
          <TextField
            label="Vehicle Number"
            name="vehNum"
            value={data.vehNum}
            onChange={(e) => handleChange(e)}
            fullWidth
            margin="normal"
            type='text'
            disabled={view || !["VEDANTA_OSL", "Admin", "KRISHCA_VEDANTA_SV"].some(role => userDetails.roleMapping.includes(role))}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="material-label">Material Name</InputLabel>
            <Select
              labelId="material-label"
              value={(isOtherSelected && !materialList.includes(data.materialName)) ? 'Others' : data.materialName}
              onChange={handleSelectChange}
              label="Material Name"
              name="materialName"
              disabled={view || !["VEDANTA_OSL", "Admin", "KRISHCA_VEDANTA_SV"].some(role => userDetails.roleMapping.includes(role))}
            >
              {
                materialList.map((val,i)=>(
                  <MenuItem value={val} key={i}>{val}</MenuItem>
                ))
              }
              <MenuItem value="Others">Others</MenuItem>
            </Select>
            {isOtherSelected && (
              <TextField
                label="Material Name"
                value={data.materialName}
                name="materialName"
                onChange={handleTextFieldChange}
                fullWidth
                margin="normal"
                type="text"
                disabled={view || !["VEDANTA_OSL", "Admin", "KRISHCA_VEDANTA_SV"].some(role => userDetails.roleMapping.includes(role))}
              />
            )}
          </FormControl>

          <FormControl fullWidth margin="normal">
              <TextField
                label="Truck Type"
                value={data.truckType}
                name="truckType"
                onChange={handleTextFieldTruckChange}
                fullWidth
                margin="normal"
                type="text"
                disabled={view || !["VEDANTA_OSL", "Admin", "KRISHCA_VEDANTA_SV"].some(role => userDetails.roleMapping.includes(role))}
              />
          </FormControl>

          {isCameraOpen && (
            <Box ref={cameraRef}>
              <CameraComponent
                facingMode='environment'
                logoSrc={Logo} // Provide the correct logo source
                onCapture={handleCapture}
              />
            </Box>
          )}
          <Box mt={2}>
            <Typography variant="subtitle1">Vehicle</Typography>
            {data?.vehAttachmentId !==0 ? (
              <Box>
                <img src={decodeBase64(data?.vehFilePath)} alt="Number" width="100%" />
              </Box>
            ) : (
              <Divider textAlign='left'
                sx={{
                    "&::before, &::after": {
                    borderColor: "primary.main",
                    },
                }}
              >
                  <Chip disabled={view || !["VEDANTA_OSL", "Admin", "KRISHCA_VEDANTA_SV"].some(role => userDetails.roleMapping.includes(role))} label="Capture Photo" color='info' onClick={() => handleOpenCamera('number')} variant='outlined' icon={<Camera />}/>
              </Divider>
            )}
          </Box>
          {
            ["KRISHCA_VEDANTA_SV", "Admin","VEDANTA_ADMIN"].some(role => userDetails.roleMapping.includes(role)) && ( 
              <>
                <Box mt={2}>
                  <Typography variant="subtitle1">Lashing</Typography>
                  <Divider textAlign='left'
                    sx={{
                        "&::before, &::after": {
                        borderColor: "primary.main",
                        },
                    }}
                  >
                      <Chip disabled={view || !["KRISHCA_VEDANTA_SV", "Admin"].some(role => userDetails.roleMapping.includes(role))} label="Capture Photos" color='info' onClick={() => handleOpenCamera('lashing')} variant='outlined' icon={<Camera />} />
                  </Divider>
                  <Box display="flex" justifyContent="space-between" mt={1}>
                    <Typography variant="body2">Start Time: {lashingStartTime}</Typography>
                    <Typography variant="body2">End Time: {lashingEndTime}</Typography>
                  </Box>
                  <Box display="flex" flexWrap="wrap" mt={1}>
                    {sortedLashingData?.map((photo, index) => (
                      <img key={index} src={decodeBase64(photo?.lashingFilePath)} alt="Lashing" width="100px" style={{ margin: 4 }} />
                    ))}
                  </Box>
                </Box>
                <Box mt={2}>
                  <Typography variant="subtitle1">Tarpaulin</Typography>
                  <Divider textAlign='left'
                    sx={{
                        "&::before, &::after": {
                        borderColor: "primary.main",
                        },
                    }}
                  >
                      <Chip disabled={view || !["KRISHCA_VEDANTA_SV", "Admin"].some(role => userDetails.roleMapping.includes(role))} label="Capture Photos" color='info' onClick={() => handleOpenCamera('tarpaulin')} variant='outlined' icon={<Camera />} />
                  </Divider>
                  <Box display="flex" justifyContent="space-between" mt={1}>
                    <Typography variant="body2">Start Time: {tarpaulinStartTime}</Typography>
                    <Typography variant="body2">End Time: {tarpaulinEndTime}</Typography>
                  </Box>
                  <Box display="flex" flexWrap="wrap" mt={1}>
                    {sortedTarpaulinData?.map((photo, index) => (
                      <img key={index} src={decodeBase64(photo?.tarpaulinFilePath)} alt="Tarpaulin" width="100px" style={{ margin: 4 }} />
                    ))}
                  </Box>
                </Box>
                <Box mt={2}>
                  <Typography variant="subtitle1">Handover</Typography>
                  <Divider textAlign='left'
                    sx={{
                        "&::before, &::after": {
                        borderColor: "primary.main",
                        },
                    }}
                  >
                    <Chip disabled={view || !["KRISHCA_VEDANTA_SV", "Admin"].some(role => userDetails.roleMapping.includes(role))} label="Capture Photos" color='info' onClick={() => handleOpenCamera('completed')} variant='outlined' icon={<Camera />}/>
                  </Divider>
                  <Box display="flex" flexWrap="wrap" mt={1}>
                    {data?.completed?.map((photo, index) => (
                      <img key={index} src={decodeBase64(photo?.completedFilePath)} alt="Handover" width="100px" style={{ margin: 4 }} />
                    ))}
                  </Box>
                </Box>
                <TextField
                  label="Worker(s) Name"
                  value={data.workersName}
                  onChange={(e) => handleChange(e)}
                  fullWidth
                  name='workersName'
                  margin="normal"
                  type='text'
                  disabled={view || !["KRISHCA_VEDANTA_SV", "Admin"].some(role => userDetails.roleMapping.includes(role))}
                  multiline
                  rows={5}
                  maxRows={6}
                />
              <Box mt={2}>
                <TextField
                  label="Weight"
                  value={data.weight}
                  onChange={(e) => handleChange(e)}
                  fullWidth
                  name='weight'
                  margin="normal"
                  type='text'
                  disabled={view || !userDetails.roleMapping.includes("VEDANTA_ADMIN")}
                  multiline
                />
              </Box>
              </>
            )
          }
          <Box mt={3} display="flex" justifyContent="space-between">
            <Button variant="outlined" onClick={handleClear}>
              CANCEL
            </Button>
            <Button disabled={view} variant="contained" color="primary" onClick={()=>handleSave("normal")}>
              SAVE
            </Button>
          </Box>
          {
            (
              (data?.status != "Approved" && data?.status != "Completed" && ["VEDANTA_OSL", "Admin", "KRISHCA_VEDANTA_SV"].some(role => userDetails.roleMapping.includes(role)) && (data?.status == "" || data?.status == "Inprogress") )
               &&
                data?.vehNum !== "" && data?.materialName !== "" && data?.truckType !== "" && data?.vehAttachmentId != 0) && (
                  <Box mt={3} sx={{textAlign:'center'}}>
                    <Button variant="contained" color='warning' onClick={()=>handleSave("Assigned")}>
                      ASSIGN
                    </Button>
                  </Box>
            )
          }
          {
            (data?.completed?.length >0 && roleRender(userDetails.roleMapping) && view != true) && (
              <Box mt={3} sx={{textAlign:'center'}}>
                <Button variant="contained" color='warning' onClick={()=>handleSave("Hand Over")}>
                  Hand Over
                </Button>
              </Box>
            )
          }
          {
            (data?.status === "Assigned" && roleRender(userDetails.roleMapping)) && (
              <Box mt={3} sx={{textAlign:'center'}}>
                <Button variant="contained" color='warning' onClick={()=>approveData(data?.id,data?.vehNum)}>
                  Approve
                </Button>
              </Box>
            )
          }
        </Box>
      ) : (
        <>
        {
          ["VEDANTA_OSL", "Admin", "KRISHCA_VEDANTA_SV"].some(role => userDetails.roleMapping.includes(role)) && (
            <div style={{display:"flex",justifyContent:'flex-end'}}>
              <Chip variant='outlined' label={"Add New"} color='info' icon={<Add />} onClick={()=>setShow(true)} />
            </div>
          )
        }
        <div className="ag-theme-alpine ag-grid-container">
          <AgGridReact
            className="ag-theme-alpine"
            defaultColDef={defaultColDef}
            rowData={loadingPackingList}
            columnDefs={columnDefs}
            onGridReady={(params) => (gridApiRef.current = params)}
            domLayout="autoHeight"
            // onCellValueChanged={handleCellValueChanged}
            pagination={true}
            paginationPageSize={5}
            paginationPageSizeSelector={[5,10,25]}
            getRowId={getRowId}
            suppressMenuHide={true}
          />
        </div>
        {/* Dialog Component */}
        <Dialog open={timeLine} onClose={() => setTimeLine(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Workflow Steps</DialogTitle>
        <DialogContent>
          <Box sx={{ maxWidth: 400 }}>
            <Stepper orientation="vertical" connector={<ZigzagStepConnector />}>
              {workflowList.map((step, index) => (
                <Step key={step.workflowId} active={true}> {/* Set each step to active */}
                  <ZigzagStepLabel
                    optional={
                      index === workflowList.length - 1 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }
                  >
                    {`${step.createdByName} - ${step.createdTime}`}
                  {step.message && (
                    <Typography variant="body2" color="textSecondary" sx={{ ml: 2 }}>
                      {step.message}
                    </Typography>
                  )}
                  </ZigzagStepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTimeLine(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
        </>
      )
    }
    </>
  );
};

export default LoadingPackingTruck;
