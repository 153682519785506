import React, { useState } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput
}
from 'mdb-react-ui-kit';
import { enqueueSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserChangePwdInterface, userForgorPwd } from '../../Const/type';
import { CustomMessage } from '../../Const/Spinner';
import { setLoading } from '../../Redux/CommonSlice';
import { forgotPasswordChange } from '../../Const';

const ForgotPassword:React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userLogin,setUserLogin] = useState<userForgorPwd>({
    username: ""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e?.target;
    const updatedUserLogin = {...userLogin};
      updatedUserLogin[name] = value;
    setUserLogin(updatedUserLogin);
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if(userLogin?.username === ""){
      CustomMessage("Enter the required field",'error',enqueueSnackbar);
      return;
    }
    dispatch(setLoading(true));
    try{
      const response = await forgotPasswordChange(userLogin);
      const {data,status} = response;
      if(status.code === 200){
        CustomMessage(status.message,'success',enqueueSnackbar);
        navigate("/");
        CustomMessage("Login now with generated password",'info',enqueueSnackbar);
      }else{
        CustomMessage(status.message,'error',enqueueSnackbar);
      }
    }catch(error:any){
      CustomMessage(error?.message,'error',enqueueSnackbar);
    }finally{
      dispatch(setLoading(false));
    }
  }

  return (
    <MDBContainer fluid>
      <form onSubmit={handleSubmit}>
        <MDBRow className='d-flex justify-content-center align-items-center h-100'>
          <MDBCol col='12'>
            <MDBCard className='bg-dark text-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '400px'}}>
              <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>
                <h2 className="fw-bold mb-2 text-uppercase">Forgot password</h2>
                <p className="text-white-50 mb-5">Please enter your Username!</p>
                <MDBInput 
                  wrapperClass='mb-4 mx-5 w-100' 
                  labelClass='text-white' 
                  label='Email address' 
                  id='formControlLg' 
                  type='email' size="lg"
                  style={{backgroundColor:"transparent",color:"white"}}
                  required
                  value={userLogin.username}
                  name='username'
                  onChange={handleChange}
                />
                 <p className="small mb-3 pb-lg-2"><a className="text-white-50" href="#" onClick={()=>navigate("/")}>Login !</a></p>
                <MDBBtn outline className='mx-2 px-5 text-white' size='lg'>
                  Submit
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </form>
    </MDBContainer>
  );
}

export default ForgotPassword;