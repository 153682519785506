import React, { useEffect, useMemo, useRef, useState } from 'react'
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {Grid,Chip, Paper, Table, TableBody, TableContainer, TableHead, TableRow, styled,Box, Button, TextField, FormControl, Select, InputLabel, MenuItem } from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useDispatch, useSelector } from 'react-redux';
import { itemGetListType, itemMasterInterface } from '../../Const/type';
import { setLoading } from '../../Redux/CommonSlice';
import { ColDef, ITooltipParams } from 'ag-grid-community';
import { RootState } from '../../Redux/store/ReduxStore';
import { CustomMessage } from '../../Const/Spinner';
import { enqueueSnackbar } from 'notistack';
import { AgGridReact } from 'ag-grid-react';
import { getItemById, getListOfItemData, insertItemMaster } from '../../Const';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const ItemMaster:React.FC = () => {
    const dispatch = useDispatch();
    const userDetails = useSelector((state:RootState)=>state.auth.user);
    const [itemMasterDefault,setItemMasterDefault] = useState<itemMasterInterface>({
        itemId:0,
        itemName:"",
        plant:"--Select--",
        stockUom:"",
        createdBy:0,
        updatedBy:0
    })

    const toolTipValueGetter = (params: ITooltipParams) => params.value == null || params.value === '' ? '- Missing -' : params.value;
    const columnDefs: ColDef[] = [
    {
      headerName: "Item - Qty",
      field: "itemName",
      filter: "agSetColumnFilter",
      sortable: true,
      editable: false,
      valueFormatter:(Params)=>`${Params.data?.itemName} - ${Params.data?.stockQty}`,
      flex: 0.5,
      // pinned: 'left',
    },
    {
      headerName: "Plant",
      field: "plant",
      filter: "agSetColumnFilter",
      sortable: true,
      editable: false,
      flex: 0.5,
      // pinned: 'left',
    },
    {
      headerName: "Action",
      field: "action",
      filter: "agSetColumnFilter",
      sortable: true,
      editable: false,
      // tooltipValueGetter: toolTipValueGetter,
      // tooltipField: "status",
      // flex: 1,
      cellRenderer:(params:any) => (
            <>
              <Chip variant='outlined' label="Edit" color='info' onClick={()=>getData(params?.data?.itemId)}/>
            </>
      )
    },
  ];

    const [show,setShow] = useState(false)
    const gridApiRef = useRef<any>(null);
    const [itemList, setItemList] = useState<itemGetListType[]>([]);
    const [listPlant,setListPlant] = useState(["--Select--","Plant1","Plant2"]);

    useEffect(()=>{
        if(itemList.length===0){
            getItemListData();
        }
    },[itemList])

      const getItemListData = async () => {
        dispatch(setLoading(true));
        try {
            const response = await getListOfItemData("All");
            let { data, status } = response;
            if (status.code === 200 && data?.length > 0) {
                setItemList(data);
            } else {
                CustomMessage(status.message, 'error', enqueueSnackbar);
            }
        } catch (error: any) {
        CustomMessage(error?.message, 'error', enqueueSnackbar);
        } finally {
        dispatch(setLoading(false));
        }
    };

    const handleShow = () => {
        setShow(true)
    }
     const defaultColDef = useMemo<ColDef>(() => {
        return {
        minWidth: 130,
        filter: true,
        };
    }, []);

    const getRowId = useMemo(() => {
        return (params: any) => params?.data?.itemId;
    }, []);

    useEffect(() => {
        if (gridApiRef.current) {
        gridApiRef.current.api.sizeColumnsToFit();
        }
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setItemMasterDefault((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleClear = () => {
        setItemMasterDefault({
            itemId:0,
            itemName:"",
            plant:"--Select--",
            stockUom:"",
            createdBy:0,
            updatedBy:0
        });
        setShow(false);
    }

     const handlePlantChange = (event:any) => {
        const { value } = event.target;
        setItemMasterDefault((prev) => ({
        ...prev,
        plant: value,  // Directly update the 'plant' field
        }));
    };

    const handleSubmit = async (e:any) => {
        e?.preventDefault();
        const data = {...itemMasterDefault};
        if(data.plant === "" || data.plant === "--Select--" || data.plant === null){
            CustomMessage("Select the plant",'error',enqueueSnackbar);
            return;
        }
        if(data.itemId === 0){
            data.createdBy = userDetails.id;
        }else{
            data.updatedBy = userDetails.id;
        }
        dispatch(setLoading(true));
        try{
            const response = await insertItemMaster(data);
            if(response.status.code === 200){
                CustomMessage(response.status.message,'success',enqueueSnackbar);
                handleClear();
                getItemListData();
            }else{
                CustomMessage(response.status.message,'error',enqueueSnackbar);
            }
        }catch(error:any){
            CustomMessage(error?.message,'error',enqueueSnackbar);
        }finally{
            dispatch(setLoading(false));
        }
    }
    const getData = async(itemId:number) => {
        dispatch(setLoading(true));
        try{
            const response = await getItemById(itemId);
            if(response.status.code === 200){
                setItemMasterDefault(response.data);
                setShow(true);
            }else{
                CustomMessage(response.status.message,'error',enqueueSnackbar);
            }
        }catch(error:any){
            CustomMessage(error?.message,'error',enqueueSnackbar);
        }finally{
            dispatch(setLoading(false));
        }
    }
    return(
    <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
        {
        !show?
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Chip
                    style={{ margin: "2px" }}
                    label="Add Item"
                    color='warning'
                    onClick={handleShow}
                    icon={<AddIcon />}
                    variant="outlined"
                />
            </div>
        :
            <form onSubmit={handleSubmit} >
                <Box mt={2}>
                    <TextField
                    label="Item"
                    value={itemMasterDefault.itemName}
                    onChange={handleChange}
                    fullWidth
                    name='itemName'
                    margin="normal"
                    type='text'
                    // disabled={view}
                    multiline
                    />
                </Box>
                <Box mt={2}>
                    <TextField
                    label="UOM"
                    value={itemMasterDefault.stockUom}
                    onChange={handleChange}
                    fullWidth
                    name='stockUom'
                    margin="normal"
                    type='text'
                    // disabled={view}
                    multiline
                    />
                </Box>
                {JSON.stringify(itemMasterDefault.plant)}
                <Box mt={2} >
                    <FormControl fullWidth>
                        <InputLabel id="plantSelect-label">Select Plant</InputLabel>
                        <Select
                        labelId="plantSelect-label"
                        id="plantSelect"
                        value={itemMasterDefault.plant || ""}  // Ensure value is valid
                        label="Select Plant"
                        onChange={handlePlantChange}
                        >
                        {listPlant.map((plant,i) => (
                            <MenuItem key={i} value={plant}>
                            {plant}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box mt={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Button
                        type="submit"
                        variant="contained"
                    >Submit</Button>
                    <Button
                        onClick={handleClear}
                        variant="outlined"
                    >Cancel</Button>
                </Box>
            </form>
        }
        </Grid>
        <Grid item xs={12}>
            <div className="ag-theme-alpine ag-grid-container">
                <AgGridReact
                className="ag-theme-alpine"
                defaultColDef={defaultColDef}
                rowData={itemList}
                columnDefs={columnDefs}
                onGridReady={(params) => (gridApiRef.current = params)}
                domLayout="autoHeight"
                // onCellValueChanged={handleCellValueChanged}
                pagination={false}
                paginationPageSize={20}
                getRowId={getRowId}
                suppressMenuHide={true}
                />
            </div>
        </Grid>
    </Grid>
    )
}
export default ItemMaster;