import React from "react";
import { Navigate, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/store/ReduxStore";

const PrivateRoute: any = (component: any) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.user.id
  );
  return isAuthenticated !==0 ? component.children : <Navigate to="/" />;
};

export default PrivateRoute;