import React, { useState } from "react";
import { LocalShipping } from '@mui/icons-material';
import { Card, CardContent, Chip, Divider, Grid, Paper, Typography } from "@mui/material";
import { Root } from "../../Const/componentStyles";
import CachedIcon from '@mui/icons-material/Cached';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ItemMaster from "./ItemMaster";
type ReportIconsType = {
  [key: string]: JSX.Element;
};

const ReportIcons: ReportIconsType = {
    "Master" : <CachedIcon />,
}

const MastersMain:React.FC = () => {
    const [showList,setShowList] = useState(false);
    const [selected,setSelected] = useState('');

    const categories = [
        {
            groupName: 'Master Data',
            items: ["Item Master",],
        },
    ];

    const handleSelect = (selected:string) => {
        setSelected(selected);
        setShowList(true);
    }
    
    const handleBack = () => {
        setShowList(false);
        setSelected('');
    }

    return(
        <div style={{margin:'15px'}}> 
            {!showList?
                <Grid container spacing={2}>
                    {categories.map((group, groupIndex) => (
                        <Grid item xs={12} key={groupIndex}>
                        <Root>
                            <Typography variant="h5" component="div">
                                <Divider textAlign="left"
                                    sx={{
                                        "&::before, &::after": {
                                            borderColor: "primary.main",
                                        },
                                    }}
                                >
                                    <Chip label={group.groupName} color='info' variant="outlined"/>
                                </Divider>
                            </Typography>
                            <Grid container spacing={2} className={'fade-in'}>
                                {group.items.map((category, categoryIndex) => (
                                    <Grid item xs={12} sm={6} md={6} lg={4} key={categoryIndex}>
                                        <Card
                                            onClick={() => handleSelect(category)}
                                        >
                                        <CardContent
                                            sx={{ display: 'flex', alignItems: 'center',cursor: 'pointer', }}
                                        >
                                                <Typography variant="h6" component="div">
                                                {ReportIcons[category]}{category}
                                                </Typography>
                                        </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Root>
                    {/* </Paper> */}
                </Grid>
            ))}
            </Grid>
            :
                <div>
                    <span>
                        <Chip
                            style={{ marginBottom:'15px'}}
                            label="Masters List"
                            color='info'
                            onClick={handleBack}
                            variant="outlined"
                        />
                    </span>
                    {
                        selected === "Item Master" && <ItemMaster />
                    }
                </div>
            }
        </div>
    )
}

export default MastersMain;