import React from 'react';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import Layout from './Component/Layout';
import CameraComponent from './Component/CameraComponent';
import Login from './Component/Login';
import CheckInOut from './Pages/CheckInOut';
import { useSelector } from 'react-redux';
import { RootState } from './Redux/store/ReduxStore';
import { Loader } from './Const/Spinner';
import PrivateRoute from './Component/PrivateRoute';
import './App.css';
import StockEntry from './Pages/Stock/StockEntry';
import LoadingPacking from './Pages/LoadingPacking/LoadingPackingTruck';
import LoadingMain from './Pages/LoadingPacking/LoadingMain';
import ReportsMain from './Pages/Reports/ReportsMain';
import ChangePassword from './Pages/ChangePwd/ChangePassword';
import ForgotPassword from './Pages/ForgotPwd/ForgotPassword';
import MastersMain from './Pages/Masters/MastersMain';

function App() {
  const loading = useSelector((state:RootState)=>state.common.data.loading);
  return (
    <>
    {
      loading && <Loader />
    }
      <Router 
        basename="/"
      >
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/forgot-pwd' element={<ForgotPassword />} />
          <Route path="/app" element={<Layout />}>
            <Route index element={<>Main</>} />
            <Route path='checkin' 
              element={
                <PrivateRoute>
                  <CheckInOut />
                </PrivateRoute> 
              } 
            />
            <Route path='stock' 
              element={
                <PrivateRoute>
                  <StockEntry />
                </PrivateRoute> 
              } 
            />
            <Route path='loading-packing' 
              element={
                <PrivateRoute>
                  <LoadingMain />
                </PrivateRoute> 
              } 
            />
            <Route path='reports' 
              element={
                <PrivateRoute>
                  <ReportsMain />
                </PrivateRoute> 
              } 
            />
            <Route path='masters' 
              element={
                <PrivateRoute>
                  <MastersMain />
                </PrivateRoute> 
              } 
            />
            <Route path='change-pwd' 
              element={
                <PrivateRoute>
                  <ChangePassword />
                </PrivateRoute> 
              } 
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
