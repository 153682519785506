import { Box, CircularProgress } from "@mui/material";
import React from "react";

const CustomMessage = (
  message: string,
  type: 'success' | 'info' | 'warning' | 'error', // Specify the possible types
  enqueueSnackbar: (message: string, options?: any) => void // Adjust the type for enqueueSnackbar
) => {
  enqueueSnackbar(message, {
    variant: type,
    persist: ['success', 'warning', 'info', 'error'].includes(type) ? false : true,
    autoHideDuration: ['success', 'warning', 'info', 'error'].includes(type) ? 3000 : undefined,
  });
};

const Loader: React.FC = () => {
  return (
    <Box className="mainLoader">
      <CircularProgress />
    </Box>
  );
};

export { CustomMessage, Loader };
