import React, { useEffect, useRef, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { TextField } from '@mui/material';

const CustomNumberEditor: React.FC<ICellEditorParams> = (props) => {
  const [value, setValue] = useState(props.value);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (!isNaN(Number(val))) {
      setValue(val);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      props.stopEditing();
    }
  };

  return (
    <TextField
      inputRef={inputRef}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      fullWidth
    />
  );
};

export default CustomNumberEditor;