import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// Omit 'content' from DialogProps to avoid type conflict
interface ResponsiveDialogProps extends Omit<DialogProps, 'content'> {
  title: string;
  content: React.ReactNode;
  agreeButtonText?: string;
  disagreeButtonText?: string;
  onAgree?: () => void;
  onDisagree?: () => void;
  children?: React.ReactNode; // For custom actions
  fullScreen: boolean;
}

const ResponsiveDialog: React.FC<ResponsiveDialogProps> = ({
  title,
  content,
  agreeButtonText = "Agree",
  disagreeButtonText = "Disagree",
  onAgree,
  onDisagree,
  children,
  fullScreen,
  ...dialogProps
}) => {
  const theme = useTheme();
//   const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleAgree = () => {
    if (onAgree) onAgree();
    if (dialogProps.onClose) dialogProps.onClose({}, 'escapeKeyDown');
  };

  const handleDisagree = () => {
    if (onDisagree) onDisagree();
    if (dialogProps.onClose) dialogProps.onClose({}, 'escapeKeyDown');
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      {...dialogProps}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        {children ? (
          children
        ) : (
          <>
            <Button onClick={handleDisagree} autoFocus>
              {disagreeButtonText}
            </Button>
            <Button onClick={handleAgree} autoFocus>
              {agreeButtonText}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ResponsiveDialog;